var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Application } from '@pixi/app';
import { Renderer, Texture } from '@pixi/core';
import { BatchRenderer } from '@pixi/core';
Renderer.registerPlugin('batch', BatchRenderer);
import { InteractionManager } from '@pixi/interaction';
Renderer.registerPlugin('interaction', InteractionManager);
import { TickerPlugin } from '@pixi/ticker';
Application.registerPlugin(TickerPlugin);
import { AppLoaderPlugin } from '@pixi/loaders';
Application.registerPlugin(AppLoaderPlugin);
import { Sprite } from '@pixi/sprite';
import { Rectangle } from '@pixi/math';
export class AnimatedVideo {
    constructor(args) {
        this.target = args.target;
        this.width = args.width;
        this.height = args.height;
        this.loadingImageUrl = args.loadingImageUrl;
        this.videoUrl = args.videoUrl;
    }
    renderStage() {
        const app = new Application({
            width: this.width,
            height: this.height,
            resolution: window.devicePixelRatio,
            transparent: true,
        });
        this.target.appendChild(app.view);
        return app;
    }
    loadResources(app, videoUrl, additionalResources = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                app.loader.add('video', videoUrl);
                if (additionalResources) {
                    const resources = Object.entries(additionalResources);
                    for (const [resourceKey, resourcePath] of resources) {
                        app.loader.add(resourceKey, resourcePath);
                    }
                }
                app.loader.load(() => {
                    app.loader.resources.video.data.volume = 0;
                    app.loader.resources.video.data.mute = true;
                    app.loader.resources.video.data.loop = true;
                    app.loader.resources.video.data.autoplay = true;
                    resolve(app.loader.resources);
                });
            });
        });
    }
    renderLoadingFrame(app, width, height, loadingFrameUrl) {
        const loadingSprite = new Sprite(Texture.from(loadingFrameUrl));
        loadingSprite.width = width;
        loadingSprite.height = height;
        app.stage.addChild(loadingSprite);
    }
    render() {
        return __awaiter(this, void 0, void 0, function* () {
            const app = this.renderStage();
            this.renderLoadingFrame(app, this.width, this.height, this.loadingImageUrl);
            const resources = yield this.loadResources(app, this.videoUrl);
            app.stage.removeChildren();
            const videoTexture = Texture.from(resources.video.data);
            const videoSprite = new Sprite(new Texture(videoTexture.baseTexture, new Rectangle(0, 0, this.width, this.height)));
            videoSprite.width = this.width;
            videoSprite.height = this.height;
            const maskSprite = new Sprite(new Texture(videoTexture.baseTexture, new Rectangle(this.width, 0, this.width, this.height)));
            maskSprite.width = this.width;
            maskSprite.height = this.height;
            videoSprite.mask = maskSprite;
            app.stage.addChild(videoSprite);
            return app;
        });
    }
}
