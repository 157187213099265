var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnimatedVideo } from './AnimatedVideo';
import { Texture } from '@pixi/core';
import { Container } from '@pixi/display';
import { Sprite } from '@pixi/sprite';
import { Rectangle } from '@pixi/math';
export class InteractiveVideo extends AnimatedVideo {
    constructor(args) {
        super(args);
        this.maskUrl = args.maskUrl;
        this.topAlphaUrl = args.topAlphaUrl;
        this.bottomAlphaUrl = args.bottomAlphaUrl;
    }
    getVideoSprites(videoTexture, alphaTexture) {
        const videoSprite = new Sprite(videoTexture);
        videoSprite.width = this.width;
        videoSprite.height = this.height;
        const videoAlphaSprite = new Sprite(alphaTexture);
        videoAlphaSprite.width = this.width;
        videoAlphaSprite.height = this.height;
        videoSprite.mask = videoAlphaSprite;
        return {
            video: videoSprite,
            alpha: videoAlphaSprite,
        };
    }
    render() {
        return __awaiter(this, void 0, void 0, function* () {
            const app = this.renderStage();
            app.stage.interactive = true;
            this.renderLoadingFrame(app, this.width, this.height, this.loadingImageUrl);
            const resources = yield this.loadResources(app, this.videoUrl, {
                interactiveMask: this.maskUrl,
                topAlpha: this.topAlphaUrl,
                bottomAlpha: this.bottomAlphaUrl,
            });
            app.stage.removeChildren();
            const videoTexture = Texture.from(resources.video.data);
            const bottomSprites = this.getVideoSprites(new Texture(videoTexture.baseTexture, new Rectangle(0, 0, this.width, this.height)), resources.bottomAlpha.texture);
            app.stage.addChild(bottomSprites.video);
            const topMaskContainer = new Container();
            const topSprites = this.getVideoSprites(new Texture(videoTexture.baseTexture, new Rectangle(this.width, 0, this.width, this.height)), resources.topAlpha.texture);
            topMaskContainer.addChild(topSprites.video);
            app.stage.addChild(topMaskContainer);
            const mask = new Sprite(resources.interactiveMask.texture);
            app.stage.addChild(mask);
            topMaskContainer.mask = mask;
            topSprites.alpha.mask = mask;
            app.stage.on('mousemove', (event) => {
                mask.position.x = event.data.global.x - mask.width / 2;
                mask.position.y = event.data.global.y - mask.height / 2;
            });
            return app;
        });
    }
}
