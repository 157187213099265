import { AnimatedVideo, InteractiveVideo } from './elements/AnimatedVideo';
if (!window.wa) {
    window.wa = {};
}
Object.assign(window.wa, {
    video: {
        createAnimatedVideo(args) {
            return new AnimatedVideo(args);
        },
        createInteractiveVideo(args) {
            return new InteractiveVideo(args);
        },
    },
});
